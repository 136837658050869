.container_header{
    border:3px ;
    /* solid #040C18; */
    /* background-color: var{--color-bg}; */
    filter:drop-shadow( 0px 4px 4px rgb(24, 28, 33));
    border-radius: 12px;
    margin-left: 1rem;
    margin-right :1rem;
    margin-top: 3rem; 
    margin-bottom: 3rem;
}
.Horizon__header{
    display: flex;
    flex-direction: row;
   
    padding:3rem 2rem 3rem;
 
    
}

.Horizon__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
    
}
.Horizon__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
    color:var(--color-text);
     margin: top 1.5rem;
}
.Horizon__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: azure;

    margin-top: 1.5rem;
}
.Horizon__header-content_input{
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
}
.Horizon__header-content_input input{
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    /* background:#052D56;? */
   
    font-size: 20px;
    color: azure;
    line-height: 27px;
    background-color: var(--color-footer);
    border:2px solid var(--color-footer);
    padding: 0 1rem;
    outline:none; 
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;  
}
.Horizon__header-content_input button{
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    background:#FF4820;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;  
    color: azure;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
}
.Horizon__header-content_people{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}
.Horizon__header-content_people img{
    /* width:181.79px;
    height: 38px; */
}
.Horizon__header-content_people p{
    margin: 0 0 0 1rem;
    font-family:var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: azure;
    text-align: center;

}
.Horizon__header-image{
    border: 3px black;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 15px;
    font-family: var(--font-family);
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
   width: '100%'; height: '100%'; position: 'relative';

}
.Dropzone{
    width: 200%;
    height: 110%;

}


@media screen and (max-width:1050px){
    .Horizon__header{
        flex-direction: column;
    }
    .Horizon__header-content{
        margin: 0 0 3rem;
    }
}
@media screen and (max-width:650px){   
     .Horizon__header h1{
        font-size: 48px;
        line-height: 60px;
    }
    .Horizon__header p{
        font-size: 16px;
        line-height: 24px;
    }
    .Horizon__header-content_people{
        flex-direction: column;
    }
    .Horizon__header-content_people p{
        margin: 0;
    }
    .Horizon__header-content_input input,
    .Horizon__header-content_input button{
        font-size: 16px;
        line-height: 24px;
    }
    
}
@media screen and (max-width:490px){
    .Horizon__header h1{
        font-size: 36px;
        line-height: 48px;
    }
    .Horizon__header p{
        font-size: 14px;
        line-height: 24px;
    }
    .Horizon__header-content_input input,
    .Horizon__header-content_input button{
        font-size: 12px;
        line-height: 16px;
    }
}