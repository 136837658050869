/* div {
    border-radius: 10px; 
    border-image: linear-gradient(#db8eff -13.86%, #0aefe9 99.55%)  103.22;
}   */
.Horizon__DataTable {
    display: flex;
    flex-direction: row;
    padding: 1cm;
     box-sizing: border-box;
    border-radius: 9px;
    background-color: #040C18;
   /* borderRadius: 15px; */
    
}
.container__DataTable {
   
   
    border-style: solid;
  
    /* filter:drop-shadow( 0px 4px 4px rgb(24, 28, 33)); */
    
    margin-left: 1rem;
    margin-right :1rem;
    margin-top: 3rem; 
    margin-bottom: 3rem;
    
}


.Horizon__DataTable-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.Horizon__DataTable-image img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.Horizon__DataTable-content {

    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

.Horizon__DataTable-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.Horizon__DataTable-content h4:last-child {
    color: #ff8A71;
}

.Horizon__DataTable-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.Horizon__DataTable-content table {
    color: azure;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 2rem;
}
.ScanData_Table{
    
    padding: 8px;
    
}

@media screen and (max-width: 950px) {
    .Horizon__DataTable {
        flex-direction: column;
    }

    .Horizon__DataTable-image {
        margin: 1rem 0;
    }

    .Horizon__DataTable-image img {
        width: unset;
        height: unset;
    }

    .Horizon__DataTable-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .Horizon__DataTable-image img {
        width: 100%;
        height: 100%;
    }
    .Horizon__DataTable{
        padding-left: 2rem;
        padding-right: 2rem;   
    }
}